import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Form } from "@remix-run/react";
import { isAuthenticated } from "~/.server/auth";
import { Button, FormControl } from "~/components/form";
import { createTitle } from "~/util/title";

export const meta: MetaFunction = () => {
  return [{ title: createTitle("ログイン") }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  return isAuthenticated(request);
}

export default function Login() {
  return (
    <Form method="post" action="/auth/microsoft">
      <FormControl mt={8}>
        <Button type="submit">ログイン</Button>
      </FormControl>
    </Form>
  );
}
